import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Logo from '../../svg/logo.svg';

const Header = () => {
  return (
    <header className="Header">
      <figure className="Header__logo">
        <AniLink cover bg="#1d55a2" to="/" activeClassName="active">
          <Logo />
        </AniLink>
      </figure>
      <nav className="Header__nav">
        <a
          href="http://my.legalplan.be/"
          target="_blank"
          rel="noopener noreferrer"
        >
          My Legal Plan
        </a>
        <AniLink cover bg="#1d55a2" to="/contact/" activeClassName="active">
          Contact
        </AniLink>
      </nav>
    </header>
  );
};

export default Header;
