import React from 'react';
import PropTypes from 'prop-types';

import '../scss/app.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import Footer from './layout/Footer';
import Header from './layout/Header';

const Layout = ({ children }) => (
  <section className="Root">
    <Header />
    <main role="main">
      {children}
    </main>
    <Footer />
  </section>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
