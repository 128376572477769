import React, { useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Pin from '../svg/marker.svg';
import Seo from '../components/Seo';

const Contact = ({ data }) => {
  const [viewport] = useState({
    width: `100%`,
    height: 300,
    latitude: 50.6407013,
    longitude: 4.6222647,
    zoom: 14,
  });

  return (
    <Layout>
      <Seo
        title="Contact"
        description="Question, recherche ou analyse juridique, l’équipe de Legal Plan dispose de l’expérience requise afin d’y répondre et de vous assister le cas échéant."
      />
      <section className="Contact">
        <section className="Contact__hero">
          <h1>Contact</h1>
        </section>
        <section className="Contact__content wrapper container">
          <section className="Contact__content__left">
            <ul className="Contact__list">
              <li className="Contact__heading">
                <h2>Adresse</h2>
              </li>
              <li>
                Avenue de la Fontaine, 4<br />
                BE-1435 Mont-Saint-Guibert
              </li>
              <li className="Contact__heading">
                <h2>Téléphone</h2>
              </li>
              <li>+32 10 65 07 76</li>
              <li className="Contact__heading">
                <h2>Email</h2>
              </li>
              <li>info@legalplan.be</li>
            </ul>
          </section>
          <section className="Contact__content__right">
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken={data.site.siteMetadata.mapboxAccessToken}
            >
              <Marker latitude={50.6407013} longitude={4.6222647}>
                <Pin style={{ width: 30, height: 30, fill: `#1d55a2` }} />
              </Marker>
            </ReactMapGL>
          </section>
        </section>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        mapboxAccessToken
      }
    }
  }
`;

export default Contact;
