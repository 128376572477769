import React from 'react';
import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Emoji from '../helpers/Emoji';

const Footer = () => {
  return (
    <footer className="Footer">
      <nav>
        <ul className="Footer__list">
          <li>
            <AniLink
              cover
              bg="#1d55a2"
              to="/mentions-legales"
              activeClassName="active"
            >
              Mentions Légales
            </AniLink>
          </li>
          <li>
            <a href="mailto:seb_crep@hotmail.com">
              <Emoji label="Powered" symbol="⚡" /> by Opeah
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
